module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"custom":["Archivo Black","Crete Round","Mukta Vaani","Playfair Display SC"],"web":[{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap"},{"name":"Mukta Vaani","file":"https://fonts.googleapis.com/css2?family=Mukta+Vaani:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap"},{"name":"Playfair Display SC","file":"https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;0&display=swap"},{"name":"Archivo Black","file":"https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap","fontDisplay":"swap"},{"name":"Crete Round","file":"https://fonts.googleapis.com/css2?family=Crete+Round&display=swap","fontDisplay":"swap"},{"name":"Uchen","file":"https://fonts.googleapis.com/css2?family=Uchen&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
